import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import commonService from './core/services/commonService';

import './App.css';
import Loader from './views/Loader/Loader';
import PageNotFound from './views/Pages/404/PageNotFound';
import Login from './views/Login/Login';
import ResetPassword from './views/Login/ResetPassword';
// Containers
//const FrontEndLayout = React.lazy(() => import('./containers/FrontEndLayout/FrontEndLayout'));
//const CommonLayout = React.lazy(() => import('./containers/CommonLayout/CommonLayout'));
const UserLayout = React.lazy(() => import('./containers/UserLayout/UserLayout'));
const AdminLayout = React.lazy(() => import('./containers/AdminLayout/AdminLayout'));
const CommonLayout = React.lazy(() => import('./containers/CommonLayout/CommonLayout'));

const loading = () => <Loader />;

class App extends Component {
  render(){
    return (
      <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <PrivateRoute path="/admin" name="Admin" component={AdminLayout} />
             {/*<Route path="/common" name="Common" component={CommonLayout} />*/}
              <ProtectedRoute path="/user" name="User" component={UserLayout} />
              {/*<Route path="/" name="Home" component={FrontEndLayout} />*/}

              <Route path="/common" name="Common" component={CommonLayout} />

              <Route path="/login" name="Login" component={Login} />
              <Route path="/reset-password/:token" name="Reset Password" component={ResetPassword} />
              <Route path="/" name="Login" component={Login} />

              <Route name="Page not found" component={PageNotFound} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return commonService.getAuth() && commonService.getLocalStorageValue("role") === "admin" ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};

const ProtectedRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return commonService.getAuth() && commonService.getLocalStorageValue("role") !== "" ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

export default App;
