import React, { Component } from 'react';

import {
  Container, Form,
  FormGroup, Label, Input, Row, Col,
  Button,
} from 'reactstrap';
import  { Redirect,Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonService from '../../core/services/commonService';
import Loader from '../Loader/Loader';

import './Login.css';
const logoUrl = `${process.env.PUBLIC_URL}/logo.png`;
class ResetPassword extends Component {
  constructor(props) {
    super(props)
  
    this.unsubscribe = null;
      this.state = {
      email: '',
      password: '',
      cpassword: '',
      userName: '',
      forgotPasswordEmail: "",
      modal: false,
      loggedIn: false,
      loading: false,      
      isLoggedIn: false,
      errors: {},
      showforgotPassword: false,
      settingsData: {},
      token: '',
    }

  }

  componentDidMount() { 
    const { match: { params } } = this.props;
    this.setState({token: params.token});
  }

  

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(!this.validateForm())
      return false;
    this.setState({loading: true}, () => {;
        commonService.postAPI("auth/setup-new-password", {token: this.state.token, newPassword: this.state.password}).then((res) => {
          if ( undefined === res.data || !res.data.status ) {
            this.setState( { loading: false } );
            toast.error(res.data.message);
            
            return;
          }
          //toast.sccess(res.data.message);
         this.props.history.push("/")
        }).catch((err) => {
          if(err.response !== undefined && err.response.status === 401) {
            localStorage.clear();
            this.props.history.push('/login');
          }
          else {
            this.setState( { loading: false, loggedIn: true } );
            toast.error(err.message);    
          }
          
        })
    });
  }
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
        formIsValid = false;
        errors["password"] = "*Please enter password.";
    }
    if (!this.state.cpassword) {
        formIsValid = false;
        errors["cpassword"] = "*Please enter confirm password.";
    }
    else if (this.state.cpassword !== this.state.password) {
        formIsValid = false;
        errors["cpassword"] = "New password and confirm password does not match.";
    }
    
   
    
    this.setState({
      loading: false,
      errors: errors
    });
    
    return formIsValid;
  }
  showForgotPasswordForm(){
    this.setState({showforgotPassword: true})
  }
  hideForgotPasswordForm(){
    this.setState({showforgotPassword: false})
  }

  render() {
    const { cpassword, password, loggedIn, loading, errors} = this.state;
    if ( loggedIn ) {
      return ( <Redirect to={`/`} noThrow /> )

    }
    return(
         <div className="auth-section" style={{backgroundImage:`url('/images/bg.jpg')`}}>
        <Container>
          {loading ? <Loader /> : <></>}
          <ToastContainer />
          <Row className="justify-content-md-center align-items-center">
            <Col md={12}>
              <div className="Login">
                <h2>Reset Password</h2>  
                <Form className="form form-content needs-validation" onSubmit={this.submitHandler} noValidate>
                  <div className="logo-section">
                    <img src = {logoUrl} alt="Logo" />
                  </div>
                  <FormGroup>
                    <Label>New Password</Label>
                    <Input
                      type="password"
                      name="password"
                      invalid={errors['password'] !== undefined && errors['password'] !== ""} value={password} onChange={this.changeHandler} id="password"
                      placeholder="New Password"
                    />
                    <div className="invalid-feedback">
                      {errors['password']}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">Confirm Password</Label>
                    <Input
                      type="password"
                      name="cpassword"
                      invalid={errors['cpassword'] !== undefined && errors['cpassword'] !== ""} value={cpassword} onChange={this.changeHandler} id="cpassword"
                      placeholder="********"
                    />
                    <div className="invalid-feedback">
                      {errors['cpassword']}
                    </div>
                  </FormGroup>
                  <FormGroup>
                      <Row>
                        <Col md={6}>
                          <Button type="submit" className="submit-btn-1">Submit</Button>
                        </Col>
                        <Col md={6}>
                          <Link to = {"/"} className="forgot-text" >Sign In?</Link>
                        </Col>
                      </Row>
                  </FormGroup>
                  
                </Form>
                
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
    );
  }
}

export default ResetPassword;