import React, { Component } from 'react';

import {
  Container, Form,
  FormGroup, Label, Input, Row, Col,
  Button,
} from 'reactstrap';
import  { Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonService from '../../core/services/commonService';
import Loader from '../Loader/Loader';
import ForgotPassword from './ForgotPassword';

import './Login.css';
const logoUrl = `${process.env.PUBLIC_URL}/logo.png`;
class Login extends Component {
  constructor(props) {
    super(props)
  
    this.unsubscribe = null;
      this.state = {
      email: '',
      password: '',
      userName: '',
      forgotPasswordEmail: "",
      modal: false,
      loggedIn: false,
      loading: false,      
      isLoggedIn: false,
      errors: {},
      showforgotPassword: false,
      settingsData: {}
    }

  }

  componentDidMount() { 
    
  }

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(!this.validateForm())
      return false;
    this.setState({loading: true}, () => {;
        commonService.postAPI("auth/sign-in", {email: this.state.email, password: this.state.password}).then((res) => {
          if ( undefined === res.data || !res.data.status ) {
            this.setState( { loading: false } );
            toast.error(res.data.message);
            if(!res.data.status) {
              if(res.data.isAccountVerified !== undefined && !res.data.isAccountVerified) {
                this.setState( { loading: true, isLoggedIn: true }, () => {
                  commonService.postAPI( `auth/resend-otp`, {email: this.state.email} )
                    .then( res => {
                      console.log(res);
                      if ( undefined === res.data || !res.data.status ) {
                        this.setState( { loading: false} );
                        toast.error(res.data.message);
                        return;
                      }
                      this.setState( { loading: false } );
                      toast.success(res.data.message);            
                    } )
                    .catch( err => {
                      this.setState( { loading: false } );
                      toast.error(err.message);
                    } )
                } )
              }
            }
            return;
          }
          
          const loggedInfo = res.data;
          const userRole = loggedInfo.data.role.toLowerCase();
          console.log(loggedInfo);
         
          if( userRole=== "admin" || userRole === "organization" || userRole === "secondary_delegation" || userRole === "investigation_team" || userRole === "company_user") {
            commonService.setLocalStorageValue( 'accessToken', loggedInfo.data.accessToken);
            //commonService.setLocalStorageValue( 'refreshToken', loggedInfo.data.refreshToken);
            commonService.setLocalStorageValue( 'role', loggedInfo.data.role);
            commonService.setLocalStorageValue( 'primaryRole', loggedInfo.data.role);
            commonService.setLocalStorageValue( 'authId', loggedInfo.data.authId);
            commonService.setLocalStorageValue( 'profilePic', loggedInfo.data.profilePic );
            commonService.setLocalStorageValue( 'userName', loggedInfo.data.firstName );
            commonService.setLocalStorageValue( 'organizationName', loggedInfo.data.organizationName );
            localStorage.setItem( 'isActivePlan', loggedInfo.data.isActivePlan );
            
            if (loggedInfo.data.isAccessThreatModule && loggedInfo.data.isAccessThreatModule.length)
              localStorage.setItem( 'isAccessThreatModule', true );
            else
              localStorage.setItem( 'isAccessThreatModule', false );

            if(userRole === "organization"){
              if(loggedInfo.data.isActivePlan){
                localStorage.setItem( 'isShowRolePopup', true );
                this.props.history.push('/user/dashboard');
              }else{
                localStorage.setItem( 'isShowRolePopup', false );
                this.props.history.push('/user/subscription');
              }
            }else if( userRole === "secondary_delegation" || userRole === "investigation_team" || userRole === "company_user" )
              this.props.history.push('/user/dashboard');
            else
              this.props.history.push("/admin/dashboard")
          }else
            toast.error("You have not permission to access dashboard");
        }).catch((err) => {
          if(err.response !== undefined && err.response.status === 401) {
            localStorage.clear();
            this.props.propHistory.push('/login');
          }else {
            this.setState( { loading: false, loggedIn: true } );
            toast.error(err.message);    
          }
          
        })
    });
  }
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
        formIsValid = false;
        errors["password"] = "*Please enter password.";
    }
    if (!this.state.email) {
        formIsValid = false;
        errors["email"] = "*Please enter your email.";
    }
    
   
    
    this.setState({
      loading: false,
      errors: errors
    });
    
    return formIsValid;
  }
  showForgotPasswordForm(){
    this.setState({showforgotPassword: true})
  }
  hideForgotPasswordForm(){
    this.setState({showforgotPassword: false})
  }

  render() {
    const { email, password, loggedIn, loading, errors} = this.state;
    if ( loggedIn ) {
      if(commonService.getLocalStorageValue('role') === "admin")
        return ( <Redirect to={`/admin`} noThrow /> )
      else
        return ( <Redirect to={`/dashboard`} noThrow /> )

    }
    return(
         <div className="auth-section" style={{backgroundImage:`url('/images/bg.jpg')`}}>
        <Container>
          {loading ? <Loader /> : <></>}
          <ToastContainer />
          <Row className="justify-content-md-center align-items-center">
            <Col md={12}>
              <div className="Login">
                <h2>Sign In</h2>  
                <Form className="form form-content needs-validation" onSubmit={this.submitHandler} noValidate>
                  <div className="logo-section">
                    <img src = {logoUrl} alt="Logo" />
                  </div>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      invalid={errors['email'] !== undefined && errors['email'] !== ""} value={email} onChange={this.changeHandler} id="email"
                      placeholder="myemail@email.com"
                    />
                    <div className="invalid-feedback">
                      Please enter your registered email-id.
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      invalid={errors['password'] !== undefined && errors['password'] !== ""} value={password} onChange={this.changeHandler} id="password"
                      placeholder="********"
                    />
                    <div className="invalid-feedback">
                      Please enter your registered password.
                    </div>
                  </FormGroup>
                  <FormGroup>
                      <Row>
                        <Col md={6}>
                          <Button type="submit" className="submit-btn-1">Submit</Button>
                        </Col>
                        <Col md={6}>
                          <p className="forgot-text" onClick = {this.showForgotPasswordForm.bind(this)}>Forgot Password?</p>
                        </Col>
                      </Row>
                  </FormGroup>
                  
                </Form>
                {this.state.showforgotPassword ? <ForgotPassword hideForgotPasswordForm = {this.hideForgotPasswordForm.bind(this)} /> : ""}
                {/*this.state.showResendVerification ? <ResendVerification hideResendVerificationForm = {this.hideResendVerificationForm.bind(this)} /> : ""*/}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
    );
  }
}

export default Login;