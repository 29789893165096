import React, { Component } from 'react';

import {
   Col, Row, Button, Form, Input, FormGroup, FormFeedback, Label, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonService from '../../core/services/commonService';

import './Login.css';

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.unsubscribe = null;
      this.state = {
      email: '',
      password: '',
      userName: '',
      name: '',
      forgotPasswordEmail: "",
      modal: false,
      loggedIn: false,
      loading: false,      
      isLoggedIn: false,
      errors: {},
      settingsData: {}
    }

  }

 

  componentDidMount() { 
    
  }

  

  
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm() {
    let errors = {};
    let formIsValid = true;
    
    if (!this.state.email) {
        formIsValid = false;
        errors["email"] = "*Please enter your email.";
    }
    if(this.state.email !== undefined && this.state.email.trim() !== ""){
      var regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if(!regex.test(this.state.email.trim())){
        formIsValid = false
        errors["email"] = 'Invalid email address';
      }
    }
    
   
    
    this.setState({
      loading: false,
      errors: errors
    });
    
    return formIsValid;
  }
  forgotPasswordHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(!this.validateForm())
      return false;
    this.setState({loading: true}, () => {
      commonService.postAPI("auth/forgot-password", {email: this.state.email}).then((res) => {
          this.setState({loading: false});
          if ( undefined === res.data || !res.data.status ) {
            toast.error(res.data.message);
            this.setState({loading: false});
            return;

          }
          toast.success(res.data.message);
      }).catch((err) => {
        if(err.response !== undefined && err.response.status === 401) {
          localStorage.clear();
          this.props.propHistory.push('/login');
        }
        else {
          this.setState( { loading: false, loggedIn: true } );
          toast.error(err.message);    
        }
      })
    });
  } 
  toggle = () => {
    this.props.hideForgotPasswordForm();
  }

  render() {
    const { email} = this.state;
    
    return (
      <Modal isOpen={true} toggle={this.toggle} className="store-modal">
          <ModalHeader toggle={this.toggle}>Forgot Password </ModalHeader>
          <Form onSubmit={this.forgotPasswordHandler} noValidate className="form-info">
            <ModalBody>              
              <Row>          
                <Col md={"12"}>
                  <FormGroup> 
                    <Label htmlFor="email">Email Address</Label>            
                    <Input type="email" placeholder="email *" id="email" name="email" value={this.state.email} onChange={this.changeHandler} required />
                    <FormFeedback>{this.state.errors[email]}</FormFeedback>
                  </FormGroup>  
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button className="submit-btn" disabled = {this.state.loading}  type="submit">Forgot</Button>
              <Button className="btnCancel" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
    );
  }
}

export default ForgotPassword;