import axios from 'axios';
//const API_BASE_URL = 'http://44.205.102.218/apis/';
const API_BASE_URL = 'https://admin.incursus.co/apis/v0.0/';

//const API_BASE_URL = 'http://localhost:8082/';  
const API_BASE_URL_WITH_OUT_VERSION = 'https://admin.incursus.co/apis/';
const WEB_URL = 'https://admin.incursus.co/';
var CryptoJS = require("crypto-js");
class ApiService {

    generateRandonNumber(length = 10) {
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    /*Get Organization Subscribe*/
    getIsSubscribe() {
        let isSubscribed = this.getLocalStorageValue("isSubscribed");
        if(isSubscribed === '' || isSubscribed === null)
          return "";
        else
          return ( this.getLocalStorageValue("isSubscribed") === "true") ? true : false;
    }
    setIsSubscribe(isSubscribe = "false") {
        isSubscribe = isSubscribe.toString();
        this.setLocalStorageValue( 'isSubscribed');
    }

    /*Get API*/
    getAPI(urlSegment) {
        return axios.get(API_BASE_URL+urlSegment);
    }
    /*Get API With Authentication header */
    getAPIWithAccessToken(urlSegment) {
        const accessToken = this.getLocalStorageValue("accessToken");
        const headers = {
            'Authorization': 'JWT '+accessToken
        }
        return axios.get(API_BASE_URL+urlSegment, {headers: headers});
    }
    /*Post API Without Authentication header */
    postAPI(urlSegment, formdata) {        
        const headers = {
            'Content-Type': 'application/json'           
        }
        return axios.post(API_BASE_URL+urlSegment, formdata, {headers: headers});
    }
    /*Post API With Authentication header */
    postAPIWithAccessToken(urlSegment, formdata){
        const accessToken = this.getLocalStorageValue("accessToken");
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT '+accessToken
        }
        return axios.post(API_BASE_URL+urlSegment, formdata, {headers: headers});
    } 

    /*Post form data API With Authentication header */
    postMultipartDataAPIWithAccessToken(urlSegment, formdata){
        const accessToken = this.getLocalStorageValue("accessToken");
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'JWT '+accessToken
        }
        return axios.post(API_BASE_URL+urlSegment, formdata, {headers: headers} );
    } 

    /*PUt API With Authentication header */
    putAPIWithAccessToken(urlSegment, formdata){
        const accessToken = this.getLocalStorageValue("accessToken");
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT '+accessToken
        }
        return axios.put(API_BASE_URL+urlSegment, formdata, {headers: headers});
    } 

    /*Delete API With Authentication header and Without parameter */
    deleteAPIWithAccessToken(urlSegment, formdata= {}){
        const accessToken = this.getLocalStorageValue("accessToken");
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT '+accessToken
        }

        //debugger;
        return axios.delete(API_BASE_URL+urlSegment, { headers: headers , data: formdata});
    } 
    /* Check user logged in or not */
    getAuth(){
        let accessToken = this.getLocalStorageValue("accessToken");        
        if(accessToken === '' || accessToken === null)
          return false;
        else
          return true;
    }
    /*Get API Url*/
    getAPIUrl(){
        return API_BASE_URL_WITH_OUT_VERSION;
    }

    getWebUrl(){
        return WEB_URL;
    }

    getLocalStorageValue(key) {
        let data = localStorage.getItem(key);        
        if(data === '' || data === null)
          return "";
        else{
          let decyptdata =  CryptoJS.AES.decrypt(localStorage.getItem(key), '2iAgencyENCRYPTION@12345').toString(CryptoJS.enc.Utf8);
          return decyptdata ? JSON.parse(decyptdata) : decyptdata;
        }
    }
    setLocalStorageValue(key, value) {
        let data = JSON.stringify(value);
        localStorage.setItem( key, CryptoJS.AES.encrypt(data, '2iAgencyENCRYPTION@12345').toString());
    }
    getStatus(status = 1){
        
      let statusText = "Active"
      if(status === 0)
        statusText = "Inactive";
      else if(status === 2)
        statusText = "Pending";
      else if(status === 3)
        statusText = "Under Negotiation";
      return statusText;
    }

    getStatusVal(status = "Active") {
        let statusText = 1;
        if(status === "Inactive")
          statusText = 0;
        else if(status === "Pending")
          statusText = 2;
        else if(status === "Under Negotiation" || status === "Block")
          statusText = 3;
        return statusText;
    }
    getUserStatus(status = 1){
        
      let statusText = "Active"
      if(status === 0)
        statusText = "Inactive";
      else if(status === 2)
        statusText = "Pending";
      else if(status === 3)
        statusText = "Block";
      return statusText;
    }

    getDateTime($utcTime, $format= 'YYYY-MM-DD H:i:s') {
        var convertedDate = "";
        if($utcTime !== undefined && $utcTime !== "") {
            let newDate = new Date($utcTime);
            let getDay = newDate.getDate();
            getDay = (getDay < 10) ? '0' + getDay : getDay;
            let getYear = newDate.getFullYear();            
            let getMonth = newDate.getMonth();
            getMonth = getMonth + 1;
            getMonth = (getMonth < 10) ? '0' + getMonth : getMonth;
            let getHour = newDate.getHours();
            getHour = (getHour < 10) ? '0' + getHour : getHour;
            let getMinutes = newDate.getMinutes();
            getMinutes = (getMinutes < 10) ? '0' + getMinutes : getMinutes;
            let getSeconds = newDate.getSeconds();
            getSeconds = (getSeconds < 10) ? '0' + getSeconds : getSeconds;
            if($format === 'YYYY-MM-DD H:i:s')
                convertedDate = getYear+'-'+getMonth+'-'+getDay+' '+getHour+':'+getMinutes+':'+getSeconds;
            else if($format === 'DD-MM-YYYY H:i:s')
                convertedDate = getDay+'-'+getMonth+'-'+getYear+' '+getHour+':'+getMinutes+':'+getSeconds;
            else if($format === 'DD-MM-YYYY H:i')
                convertedDate = getDay+'-'+getMonth+'-'+getYear+' '+getHour+':'+getMinutes;
            else if($format === 'YYYY/MM/DD H:i:s')
                convertedDate = getYear+'/'+getMonth+'/'+getDay+' '+getHour+':'+getMinutes+':'+getSeconds;
            else if($format === 'MM/DD/YYYY H:i:s')
                convertedDate = getMonth+'/'+getDay+'/'+getYear+' '+getHour+':'+getMinutes+':'+getSeconds;
            else if($format === 'DD/MM/YYYY H:i:s')
                convertedDate = getDay+'/'+getMonth+'/'+getYear+' '+getHour+':'+getMinutes+':'+getSeconds;

        }
        return convertedDate;
    }
    getDate($utcTime, $format= 'MM/DD/YYYY') {
        var convertedDate = "";
        if($utcTime !== undefined && $utcTime !== "") {
            let newDate = new Date($utcTime);
            let getDay = newDate.getDate();
            getDay = (getDay < 10) ? '0' + getDay : getDay;
            let getYear = newDate.getFullYear();            
            let getMonth = newDate.getMonth();
            getMonth = getMonth + 1;
            getMonth = (getMonth < 10) ? '0' + getMonth : getMonth;
           
            if($format === 'YYYY-MM-DD')
                convertedDate = getYear+'-'+getMonth+'-'+getDay;
            else if($format === 'DD-MM-YYYY')
                convertedDate = getDay+'-'+getMonth+'-'+getYear;
            else if($format === 'YYYY/MM/DD')
                convertedDate = getYear+'/'+getMonth+'/'+getDay;
            else if($format === 'MM/DD/YYYY')
                convertedDate = getMonth+'/'+getDay+'/'+getYear;
            else if($format === 'DD/MM/YYYY')
                convertedDate = getDay+'/'+getMonth+'/'+getYear;

        }
        return convertedDate;
    }

    encryptAffiliateCode(affilateCode) {
        if(affilateCode !== ""){
            let finalString = "";
            for(let i =0; i < affilateCode.length; i++){
                finalString += affilateCode[i]+this.generateRandonNumber(4);
            }
            return finalString;
        }
        else
            return "";
    }
    decryptAffiliateCode(encryptedCode) {
        if(encryptedCode !== ""){
            let finalString = "";
            for(let i =0; i < encryptedCode.length; i += 5){
                
                finalString += encryptedCode[i];
            }
            return finalString;
        }
        else
            return "";
    }
    getCurrentDate(){
        var d = new Date();

        var month = d.getMonth()+1;
        var day = d.getDate();

        var output = d.getFullYear() + '_' +
            ((''+month).length<2 ? '0' : '') + month + '_' +
            ((''+day).length<2 ? '0' : '') + day;
        return output;
    }
}

export default new ApiService();